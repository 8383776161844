@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Inter:wght@600&display=swap');

.Header {
    width: 100%;
    max-height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding: 0 20px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.header-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #030303;
    padding-right: 20px;
}

.header-title .title {
    margin: 0;
}

.linkTitle {
    color: inherit; /* Matches the color with the parent element */
    text-decoration: none; /* Removes the underline */
}

.links-container {
    display: flex;
    justify-content: flex-end; /* изменено с space-between на flex-end */
    width: 30%; /* уменьшено с 50% до 30% */
    flex-wrap: wrap; /* добавлено */
}

.header-privacy a,
.header-terms a,
.header-contact a {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #262626;
    text-decoration: none;
    padding: 0 10px;
}

/* Мобильная версия */

@media (max-width: 768px) {
    .Header {
        padding: 0 10px;
    }

    .header-container {
        flex-direction: column;
        width: 100%;
        max-width: none;
    }

    .header-title {
        margin-bottom: 10px;
    }

    .links-container {
        width: 100%;
        justify-content: space-around;
    }
}
