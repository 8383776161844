.promoContainer {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;
}

.promoRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    flex: 2;
    padding-right: 20px;
}

.textContainer h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 72px;
    color: #030303;
}

.textContainer p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #030303;
    margin-top: 20px;
}

.promoImageContainer {
    flex: 3;
    text-align: right;
    padding-left: 20px;
}

.promoImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.promoImageContainer a {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #777777;
    display: inline-block;
}

.secondRowContainer {
    display: flex;
    justify-content: space-between;
}

.featuresContainer {
    flex: 1;
    padding: 0 20px;
}

.featuresContainer p,
.featuresContainer ul {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    color: #030303;
    list-style: disc;
    text-align: justify;
    align-self: flex-start;
}

.featuresContainer ul {
    padding-left: 40px;
}

.featuresContainer ul li {
    margin-bottom: 15px;
}

.appStoreLinkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px; /* или любое другое значение для отступа сверху, если требуется */
}

/* Mobile version */
@media (max-width: 768px) {
    .promoRow {
        flex-direction: column;
    }

    .textContainer, .promoImageContainer {
        padding: 0;
    }

    .textContainer h2 {
        font-size: 48px; /* decrease font size for mobile */
    }

    .textContainer p, .featuresContainer p, .featuresContainer ul {
        font-size: 16px; /* decrease font size for mobile */
    }

    .secondRowContainer {
        flex-direction: column;
    }

    .featuresContainer {
        padding: 0;
    }
}