.Footer {
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 0 20px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.footer-block {
    flex: 1 0 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 200px;
}

.footer-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.footer-logo {
    max-width: 120px;
    height: auto;
    margin-bottom: 20px;
}

.footer-text-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #030303;
    margin-top: 0;
    margin-bottom: 10px;
    white-space: nowrap;
}

.linkTitle {
    color: inherit; /* Matches the color with the parent element */
    text-decoration: none; /* Removes the underline */
}

.copyright-info {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #030303;
}

.link {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #030303;
    text-decoration: none;
    margin-bottom: 10px;
}

.active-link {
    color: #3164F4;
}

@media (max-width: 600px) {
    .footer-block {
        flex: 1 0 100%;
    }
}
