.App-store-link {
    display: flex;
    justify-content: flex-end;
}

.appstore-icon {
    max-width: 250px;
    height: auto;
}

@media (max-width: 600px) {
    .App-store-link {
        justify-content: center;
        margin-bottom: 26px;
    }
}
