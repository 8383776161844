@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Inter:wght@600&display=swap');

.feedbackSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedbackContainer {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
}

.feedbackInput {
    width: 1200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
}

.emailInput {
    width: 600px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
}

.errorText {
    color: red;
    margin-top: 5px;
}

.error {
    border-color: red;
}

.submitButton {
    width: 20%;
    padding: 15px;
    border: none;
    border-radius: 20px;
    background-color: #228B22;
    color: white;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.feedbackInput.error, .emailInput.error {
    border-color: red;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    .feedbackInput,
    .emailInput {
        width: 100%;
    }
    .submitButton {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .feedbackInput,
    .emailInput {
        width: 100%;
        font-size: 12px;
    }
    .submitButton {
        width: 100%;
        font-size: 14px;
    }
}