/*:root {*/
/*    --accent-color: #FF0000;*/
/*    --info-color: #296CAA;*/
/*    --main-color: #228B22;*/
/*    --neutral-color: #808080;*/
/*    --third-color: #00AB73;*/
/*    box-sizing: border-box;*/
/*}*/

/**, *::before, *::after {*/
/*    box-sizing: inherit;*/
/*}*/

/*html, body {*/
/*    height: 100%; !* Set the height of the body to 100% *!*/
/*}*/

/*.App {*/
/*    display: grid;*/
/*    grid-template-rows: auto 1fr auto;*/
/*    min-height: 100%;*/
/*}*/

/*.main-container {*/
/*    flex: 1; !* Allow main content to expand and contract *!*/
/*    padding: 20px;*/
/*    max-width: 1200px;*/
/*    margin: 0 auto;*/
/*}*/

/*.section-title {*/
/*    font-size: 1.5em;*/
/*    text-transform: uppercase;*/
/*    color: var(--info-color);*/
/*    margin-bottom: 20px;*/
/*    border-bottom: 2px solid var(--info-color); !* Added underline effect *!*/
/*}*/

/*.row-container {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*.half-section {*/
/*    flex: 1 0 45%;*/
/*    margin: 20px 2.5% 20px 0;*/
/*    padding: 40px 20px;*/
/*    border-radius: 30px;*/
/*    border: 1px solid var(--info-color);*/
/*    width: 48%;*/
/*}*/

/*.text, .cta-text {*/
/*    color: var(--neutral-color);*/
/*}*/

/*.cta-section {*/
/*    background: linear-gradient(to right, var(--accent-color), var(--third-color)); !* Added background gradient *!*/
/*    color: #fff;*/
/*    padding: 20px;*/
/*    text-align: center;*/
/*}*/

/*.additional-section {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.additional-section .half-section {*/
/*    width: auto;*/
/*    margin: 0;*/
/*}*/

/*.additional-section .text-big {*/
/*    font-size: 1.3em; !* Increased font size *!*/
/*    text-align: center;*/
/*}*/

/*.text-big {*/
/*    font-size: 1.2em;*/
/*    font-weight: bold;*/
/*    color: var(--neutral-color);*/
/*}*/

/*.text-center {*/
/*    text-align: center;*/
/*}*/

/*.centered {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.App-store-link img {*/
/*    height: 40px;*/
/*}*/

/* Глобальные стили для всего сайта */

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    overflow-x: hidden;
}

/* Стили для главного контейнера приложения */

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

/* Стили для основного контейнера */

.main-container {
    margin: 0 auto;
    padding: 1em;
    max-width: 1200px;
    width: 90%;
}

/* Стили для футера и хедера */

.header-container, .footer-container {
    margin: 20px;
}

/* Мобильная верстка */

@media (max-width: 768px) {
    .main-container {
        width: 95%;
    }

    .header-container, .footer-container {
        margin: 10px;
    }
}

