@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Inter:wght@600&display=swap');

.contactUs {
    padding-top: 0;
    margin-top: 0;
    flex-direction: row;
    /*align-items: flex-start;*/
}

.contactUsContainer {
    /*width: 100%;*/
    /*margin: 0 auto;*/
}

.contactUsTitle {
    text-align: left;
}

.contactUsTitle h1 {
    font-size: 2rem;
}
