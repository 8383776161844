@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Inter:wght@400&display=swap');

.additionalSection {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f6f6f6;
}

.lastSectionRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.imageContainer {
    flex: 3;
    text-align: left;
    padding-left: 20px;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imageContainer a {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #777777;
    display: inline-block;
}

.textContainer {
    display: flex;
    flex-direction: column;
    flex: 2;
    padding-right: 20px;
    justify-content: center;
}

.halfSection {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    text-align: center;
}

.textBig {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    color: #030303;
    line-height: 1.5;
    margin-top: 20px;
    text-align: justify;
}

@media (max-width: 768px) {
    .lastSectionRow {
        flex-direction: column-reverse;
    }

    .textBig {
        font-size: 20px;
    }

    .textContainer, .imageContainer {
        padding: 0;
    }
}

/* Custom styles for warning text */
.warningTextContainer {
    padding: 20px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin-top: 20px;
    border-radius: 20px;
    text-align: justify;
}

.warningText {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #030303;
    line-height: 1.5;
}
