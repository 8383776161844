.bannerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.9);
    color: white;
    padding: 30px;
    display: flex;
    justify-content: space-around;
    align-self: center;
    align-items: center;
    z-index: 1000;
}

.bannerText {
    flex: 1;
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;
    align-self: center;
    text-align: justify;
}

.acceptButton {
    padding: 10px 20px;
    background-color: #4CAF50;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 15px;
}

.acceptButton:hover {
    background-color: #45a049;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 600px) {
    .bannerContainer {
        flex-direction: column; /* Столбцовое расположение для мобильных */
        padding: 20px; /* Меньший отступ */
    }

    .bannerText {
        margin-right: 0;
        margin-bottom: 20px; /* Дополнительный отступ снизу для текста */
        font-size: 16px; /* Меньший размер шрифта */
    }

    .acceptButton {
        padding: 12px 24px; /* Увеличенный размер кнопки для удобства нажатия */
    }
}