.screenshotContainer {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.screenshotRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.screenshot {
    width: 22%;
    margin: 1%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.screenshot:hover {
    transform: scale(1.05);
}

/* Mobile version */
@media (max-width: 768px) {
    .screenshot {
        width: 45%; /* Increase size for mobile */
        margin: 2.5%; /* Increase margin for mobile */
    }
}
